import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Loader from '../../common/Loader';
import FilterTag from '../../common/FilterTag';
import NoRecords from '../../common/NoRecords';
import SearchInput from '../../common/SearchInput';
import RcPagination from '../../common/RcPagination';
import TableHeader from '../planManagement/TableHeader';
import MultiSelectDropdown from '../../common/MultiSelectDropdown';
import { DashboardCardStyle, UserAvatar } from './DashboardStyles';
import {
    getInitialName,
    displayNumberFormat,
} from '../../common/CommonMethods';
import {
    PATH_CANDIDATE_DETAIL,
    dashboardTableHeadersOptions,
    dashboardFilters,
} from '../../constants';
import {
    getDashboardCard,
    getDashboardCandidateList,
    getDashboardFilterOptions,
} from '../../api/DashboardApi';
import {
    DashboardCandidateFiltersType,
    DashboardCandidateListType,
    DashboardCardType,
    SortType,
} from '../../types/type';
import {
    Button,
    DropDownStyle,
    FilterStyle,
    InputSearch,
    TableBody,
    handleError,
} from '../../common';
import {
    EnrolledUser,
    FilterImg,
    FullUser,
    GreenUpArrow,
    RedDownArrow,
    RevenueCard,
    TealCross,
} from '../../assets/images';

const initFilters = {
    pageNumber: 1,
    pageSize: 10,
    sortType: 'DESC',
    sort: 'CreatedDate',
};

const Dashboard = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<any>({ pageLoader: true });
    const [cardDetails, setCardDetails] = useState([]);
    const [candidateList, setCandidateList] = useState<
        DashboardCandidateListType[]
    >([]);
    const [selectedFilters, setSelectedFilters] = useState<
        DashboardCandidateFiltersType | any
    >(initFilters);
    const [filterOptions, setFilterOptions] = useState<any>({});
    const [showFilterUI, setShowFilterUI] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [selectedMultiFilters, setSelectedMultiFilters] = useState<any>([]);
    const [isAscending, setIsAscending] = useState<SortType>({
        value: false,
        name: '',
    });
    const [filterTagNames, setFilterTagNames] = useState<any>({});

    const getDashboardCardDetails = () => {
        getDashboardCard()
            .then((res) => {
                setCardDetails(res?.data);
            })
            .catch((err) => handleError(err));
    };

    useEffect(() => {
        if (showFilterUI) {
            setIsLoading({ filterLoader: true });
            getDashboardFilterOptions()
                .then((res) => {
                    setFilterOptions(res?.data);
                })
                .catch((err) => handleError(err))
                .finally(() => setIsLoading({ filterLoader: false }));
        }
    }, [showFilterUI]);

    useEffect(() => {
        setIsLoading({ tableLoader: true });
        getDashboardCandidateList(
            {
                ...selectedFilters,
                pageNumber: searchInput ? 1 : selectedFilters?.pageNumber,
            },
            searchInput
        )
            .then((response: any) => {
                setCandidateList(response?.data?.candidat_List);
            })
            .catch(() => setCandidateList([]))
            .finally(() => setIsLoading({ tableLoader: false }));
    }, [selectedFilters, searchInput]);

    const handlePageChange = (pageNumber: number) => {
        setSelectedFilters({ ...selectedFilters, pageNumber });
    };

    useEffect(() => {
        getDashboardCardDetails();
    }, []);

    const getProfitLoss = (thisMonthValue: number, lastMonthValue: number) => {
        const profitLoss = thisMonthValue - lastMonthValue;
        const percentage =
            lastMonthValue !== 0 ? (profitLoss / lastMonthValue) * 100 : 0;
        return (
            <>
                <img
                    src={percentage >= 0 ? GreenUpArrow : RedDownArrow}
                    alt="arrow"
                    className="mr-1"
                />
                {displayNumberFormat(Math.abs(percentage), '', '%')}
            </>
        );
    };

    const getRevenueDifference = (
        name: string,
        value: string,
        title: string,
        property: string
    ) => {
        return (
            <div className={property}>
                {displayNumberFormat(
                    value,
                    title?.includes('Revenue') ? '$' : '',
                    ''
                )}
                <div className="small-text">{name}</div>
            </div>
        );
    };

    const getCardIconColor = (title: string, type: string) => {
        switch (title) {
            case 'Enrolled Candidates':
                return type === 'icon' ? EnrolledUser : 'purple-border';
            case 'Total Number Of Contacts':
                return type === 'icon' ? FullUser : 'teal-border';
            default:
                return type === 'icon' ? RevenueCard : 'brown-border';
        }
    };

    const getThisLastMonthCard = (
        name: string,
        value: string,
        title: string,
        otherMonth: string
    ) => {
        return (
            <div className="col-6 mt-2">
                <div className="dashboard-style">
                    <div className="card-header small-header text-break">
                        {displayNumberFormat(
                            value,
                            title?.includes('Revenue') ? '$' : '',
                            ''
                        )}
                        <span className="card-header small-header ml-2">
                            {name.includes('This') ? (
                                <>
                                    {getProfitLoss(
                                        Number(value),
                                        Number(otherMonth)
                                    )}
                                </>
                            ) : null}
                        </span>
                    </div>
                    <div className="grey-text mt-1">{name}</div>
                    <span className="separated-line" />
                    {name.includes('This') ? (
                        <div className={getCardIconColor(title, 'line')} />
                    ) : null}
                </div>
            </div>
        );
    };

    const ThemeData =
        localStorage.getItem('theme') &&
        JSON.parse(localStorage.getItem('theme') || '');

    const generateCards = () => {
        return (
            <div className="row mt-4">
                {cardDetails?.map((item: DashboardCardType) => (
                    <div
                        className={`${
                            ThemeData?.type === 'Proximity'
                                ? 'col-lg-4 col-md-12 pl-lg-2 mb-lg-0 mb-3'
                                : 'col-lg-6 '
                        }`}
                        key={item?.id}
                    >
                        <DashboardCardStyle
                            className={`${
                                ThemeData?.type !== 'Proximity' &&
                                item?.title === 'Revenue Generated'
                                    ? 'd-none'
                                    : 'fix-height'
                            }`}
                        >
                            <div className="dashboard-card fix-height">
                                <p className="card-header font-regular mb-3 pb-1">
                                    {item?.title}
                                </p>

                                <div className="row mt-3">
                                    <div className="col-3">
                                        <img
                                            className="card-image"
                                            src={getCardIconColor(
                                                item?.title,
                                                'icon'
                                            )}
                                            alt="revenue"
                                        />
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <div className="col-12 mb-2 ">
                                                <div className="page-title mb-1">
                                                    {displayNumberFormat(
                                                        item?.total,
                                                        item?.title?.includes(
                                                            'Revenue'
                                                        )
                                                            ? '$'
                                                            : '',
                                                        ''
                                                    )}
                                                </div>
                                                {item?.title?.includes(
                                                    'Revenue Generated'
                                                ) ? (
                                                    <div className="d-flex">
                                                        {getRevenueDifference(
                                                            'Contributions',
                                                            item?.contributions,
                                                            item?.title,
                                                            'grey-long-text mr-2'
                                                        )}

                                                        <div>+</div>
                                                        {getRevenueDifference(
                                                            'Subscriptions',
                                                            item?.subscriptions,
                                                            item?.title,
                                                            'grey-long-text text-break ml-2'
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                            {getThisLastMonthCard(
                                                'This month',
                                                item?.thisMonth,
                                                item?.title,
                                                item?.lastMonth
                                            )}
                                            {getThisLastMonthCard(
                                                'Last month',
                                                item?.lastMonth,
                                                item?.title,
                                                item?.thisMonth
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DashboardCardStyle>
                    </div>
                ))}
            </div>
        );
    };

    const getBulletPoint = (title: string) => {
        switch (title) {
            case 'Starter':
                return 'bullet-points orange-dot';
            case 'Enterprise':
                return 'bullet-points teal-dot';
            default:
                return 'bullet-points';
        }
    };

    const getStatusColor = (
        isActive: boolean,
        type: string,
        isDataHold: boolean
    ) => {
        if (isDataHold) {
            return type === 'status' ? 'Data hold' : 'capsule-status data';
        }
        if (!isActive)
            return type === 'status' ? 'Inactive' : 'capsule-status inactive';

        return type === 'status' ? 'Active' : 'capsule-status active';
    };

    const handleInputchange = (searchText: string) => {
        setSearchInput(searchText);
    };

    const applyFilters = () => {
        Object.keys(selectedMultiFilters).forEach((key) => {
            if (selectedMultiFilters[key]?.length === 0) {
                delete selectedMultiFilters[key];
            }
        });

        const updatedArray: any = [];
        dashboardFilters?.map((op: any) => {
            if (selectedMultiFilters?.[op.key]) {
                if (
                    op.choices === 'contactAndDonationRaisedLimitModel' &&
                    selectedMultiFilters[op.key]
                ) {
                    updatedArray.push({
                        [op.key]: selectedMultiFilters[op.key] || 0,
                    });
                } else {
                    updatedArray.push({
                        [op.key]: selectedMultiFilters[op.key]
                            ?.map((item: { label: string }) => item.label)
                            .join(','),
                    });
                }
            }
            return null;
        });
        let convertedObject = updatedArray.reduce(
            (result: any, currentObject: any) => {
                const key = Object.keys(currentObject)[0];
                return { ...result, [key]: currentObject[key] };
            },
            {}
        );

        setFilterTagNames(convertedObject);
        if (convertedObject.contacts) {
            convertedObject = {
                ...convertedObject,
                contacts: Number(convertedObject?.contacts),
            };
        }
        if (convertedObject.donationraised) {
            convertedObject = {
                ...convertedObject,
                donationRaised: Number(convertedObject?.donationraised),
            };
        }
        delete convertedObject.donationraised;

        setSelectedFilters({
            ...selectedFilters,
            ...convertedObject,
            pageNumber: Object.keys(convertedObject).length
                ? 1
                : selectedFilters?.pageNumber,
        });
        setShowFilterUI(false);
    };

    const getFieldName = (field: string) => {
        switch (field) {
            case 'Name':
                return 'FirstName';
            case 'Race type':
                return 'RaceType';
            default:
                return field;
                break;
        }
    };

    const handleSorting = (order: string, field: string) => {
        setIsAscending({ value: order === 'ASC', name: field });
        setSelectedFilters({
            ...selectedFilters,
            sortType: order,
            sort: getFieldName(field),
        });
    };

    const getSortColor = (isVal: boolean, name: string, cName: string) => {
        return isVal && name === cName ? '#414042' : '#bcbec0';
    };

    const removeFilter = (key: string) => {
        const initialFilters = { ...selectedFilters };
        const initialTags = { ...filterTagNames };
        delete initialFilters[
            key === 'donationraised' ? 'donationRaised' : key
        ];
        delete initialTags[key];
        const initialKeys = Object.keys(initialTags);
        const tempMultiSelectOptions: any = {};
        initialKeys.forEach((ele) => {
            if (selectedMultiFilters[ele])
                tempMultiSelectOptions[ele] = selectedMultiFilters[ele];
        });
        setFilterTagNames(initialTags);
        setSelectedMultiFilters(tempMultiSelectOptions);
        setSelectedFilters(initialFilters);
    };

    const getFilterKeyNames = (key: string) => {
        switch (key) {
            case 'raceType':
                return 'Race type';
            case 'donationraised':
                return 'Donation raised';
            default:
                return key;
        }
    };

    const getFilterValues = (value: string, key: string) => {
        if (value.includes('All')) return 'All';
        if (key === 'contacts') return displayNumberFormat(value, '', '');
        if (key === 'donationraised')
            return displayNumberFormat(value, '$', '');
        return value;
    };

    const getFilterTags = () => {
        const fields: any[] = [];
        if (filterTagNames) {
            Object.keys(filterTagNames).forEach((key: any) => {
                fields.push(
                    <li className="drop-down-list">
                        <FilterTag className="capitalize">
                            {getFilterKeyNames(key)} -{' '}
                            {getFilterValues(filterTagNames[key], key)}
                            {isLoading?.tableLoader ? null : (
                                <img
                                    className="cross-icon"
                                    src={TealCross}
                                    alt="cross"
                                    role="presentation"
                                    onClick={() => removeFilter(key)}
                                />
                            )}{' '}
                        </FilterTag>
                    </li>
                );
            });
            return fields;
        }
        return null;
    };

    const getSelectedSliderNo = (item: any) => {
        if (item?.key === 'contacts')
            return `(${displayNumberFormat(
                selectedMultiFilters?.contacts || 0,
                '',
                ''
            )})`;
        if (item?.key === 'donationraised')
            return `(${displayNumberFormat(
                selectedMultiFilters?.donationraised || 0,
                '$',
                ''
            )})`;
        return '';
    };

    const handleClearAll = () => {
        setShowFilterUI(false);
        setSelectedMultiFilters([]);
        setSelectedFilters(initFilters);
        setFilterTagNames({});
    };

    const getTerminatedClassStatus = (details: DashboardCandidateListType) => {
        return details?.isTerminated
            ? 'capsule-status terminated'
            : getStatusColor(details?.isActive, 'color', details?.isDataHold);
    };

    const getTerminatedLabel = (details: DashboardCandidateListType) => {
        return details?.isTerminated
            ? 'Terminated'
            : getStatusColor(details?.isActive, 'status', details?.isDataHold);
    };

    return (
        <div className="body-container">
            {isLoading?.pageLoader ? (
                <Loader />
            ) : (
                <>
                    {generateCards()}
                    <div className="row">
                        <div className="col-12 my-4">
                            <div className="page-title">Candidates </div>
                        </div>
                        <div className="col-md-12 pb-4">
                            <FilterStyle>
                                <ul className="dashboard-filter">
                                    <li className="input-search">
                                        <InputSearch>
                                            <SearchInput
                                                setDebouncedInputValue={
                                                    handleInputchange
                                                }
                                            />
                                        </InputSearch>
                                    </li>
                                    <li className="cursor">
                                        <div
                                            className="filter-img"
                                            onClick={() =>
                                                setShowFilterUI(!showFilterUI)
                                            }
                                            role="presentation"
                                        >
                                            <img src={FilterImg} alt="" />
                                        </div>
                                    </li>
                                    {getFilterTags()}
                                </ul>

                                {showFilterUI ? (
                                    <div className="dashboard-filter-click">
                                        <div className="filter-box">
                                            {isLoading.filterLoader ? (
                                                <Loader />
                                            ) : (
                                                <div className="row">
                                                    {dashboardFilters.map(
                                                        (item: any) => (
                                                            <div
                                                                className="col-md-4 py-2"
                                                                key={item?.name}
                                                            >
                                                                <p className="title">
                                                                    {item?.name}{' '}
                                                                    {getSelectedSliderNo(
                                                                        item
                                                                    )}
                                                                </p>
                                                                {item?.type ===
                                                                'dropdown' ? (
                                                                    <DropDownStyle>
                                                                        <MultiSelectDropdown
                                                                            options={
                                                                                filterOptions?.[
                                                                                    item?.choices
                                                                                ]
                                                                            }
                                                                            label={
                                                                                item?.name
                                                                            }
                                                                            value={
                                                                                item?.key
                                                                            }
                                                                            multiSelectedValues={
                                                                                selectedMultiFilters
                                                                            }
                                                                            setMultiSelectedValues={
                                                                                setSelectedMultiFilters
                                                                            }
                                                                        />
                                                                    </DropDownStyle>
                                                                ) : (
                                                                    <div className="pt-2">
                                                                        <input
                                                                            type="range"
                                                                            className="slider w-100"
                                                                            min="0"
                                                                            max={
                                                                                filterOptions?.[
                                                                                    item?.choices
                                                                                ]?.[
                                                                                    item?.key
                                                                                ]
                                                                            }
                                                                            value={
                                                                                selectedMultiFilters?.[
                                                                                    item?.key ===
                                                                                    'donationRaised'
                                                                                        ? 'donationraised'
                                                                                        : item.key
                                                                                ] ||
                                                                                0
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                setSelectedMultiFilters(
                                                                                    {
                                                                                        ...selectedMultiFilters,
                                                                                        [item.key]:
                                                                                            event
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}

                                            <div className="row">
                                                <div className="col-12 text-right pt-3">
                                                    <Button
                                                        className="mr-2 btn-border"
                                                        onClick={handleClearAll}
                                                    >
                                                        Clear all
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            applyFilters()
                                                        }
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </FilterStyle>
                        </div>
                    </div>
                    {isLoading.tableLoader ? (
                        <Loader />
                    ) : (
                        <div className="table-responsive">
                            <TableBody className="mb-3">
                                <thead className="grey-header">
                                    <tr>
                                        {dashboardTableHeadersOptions
                                            .filter((header) => {
                                                // Filter out the "Contribution" column if themeData type is "proximity"
                                                return !(
                                                    ThemeData?.type !==
                                                        'Proximity' &&
                                                    header.title ===
                                                        'Contributions'
                                                );
                                            })
                                            .map((header) => (
                                                <TableHeader
                                                    key={header.title}
                                                    label={header.title}
                                                    keyVal="demo"
                                                    noSort={['']}
                                                    accessor={header?.title}
                                                    upColor={getSortColor(
                                                        isAscending?.value,
                                                        isAscending?.name,
                                                        header?.title
                                                    )}
                                                    downColor={getSortColor(
                                                        !isAscending?.value,
                                                        isAscending?.name,
                                                        header?.title
                                                    )}
                                                    handleSorting={
                                                        handleSorting
                                                    }
                                                    isAscending={isAscending}
                                                    cssStyle={{}}
                                                />
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {candidateList && candidateList.length ? (
                                        candidateList.map(
                                            (
                                                item: DashboardCandidateListType
                                            ) => (
                                                <tr
                                                    key={item?.candidateId}
                                                    className="cursor"
                                                    role="presentation"
                                                    onClick={() =>
                                                        navigate(
                                                            PATH_CANDIDATE_DETAIL.replace(
                                                                ':id',
                                                                item?.candidateId
                                                            )
                                                        )
                                                    }
                                                >
                                                    <td>
                                                        <UserAvatar>
                                                            <div className="user-profile">
                                                                {getInitialName(
                                                                    item?.firstName,
                                                                    item?.lastName
                                                                )}
                                                            </div>
                                                            <div className="user-details ml-2 pl-1 capitalize">
                                                                {
                                                                    item?.firstName
                                                                }{' '}
                                                                {item?.lastName}
                                                                {item?.plan?.includes(
                                                                    'NotPaid'
                                                                ) ? (
                                                                    ''
                                                                ) : (
                                                                    <div
                                                                        className={`${
                                                                            item?.isCancelled
                                                                                ? 'capsule-status terminated'
                                                                                : getTerminatedClassStatus(
                                                                                      item
                                                                                  )
                                                                        } mt-1`}
                                                                    >
                                                                        {item?.isCancelled
                                                                            ? 'Cancelled'
                                                                            : getTerminatedLabel(
                                                                                  item
                                                                              )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </UserAvatar>
                                                    </td>
                                                    <td>
                                                        {' '}
                                                        <div className="bullet-text ">
                                                            {' '}
                                                            <div
                                                                className={getBulletPoint(
                                                                    item?.plan?.split(
                                                                        '~'
                                                                    )?.[0]
                                                                )}
                                                            />{' '}
                                                            {
                                                                item?.plan?.split(
                                                                    '~'
                                                                )?.[0]
                                                            }
                                                        </div>
                                                    </td>

                                                    <td>
                                                        {item?.contributions &&
                                                            displayNumberFormat(
                                                                item?.contributions,
                                                                '$',
                                                                ''
                                                            )}
                                                    </td>
                                                    <td>{item?.racetype}</td>
                                                    <td>{item?.city}</td>
                                                    <td>{item?.state}</td>
                                                    <td className="link-text">
                                                        {displayNumberFormat(
                                                            item?.contacts,
                                                            '',
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan={
                                                    dashboardTableHeadersOptions.length
                                                }
                                            >
                                                <NoRecords />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </TableBody>
                            <RcPagination
                                totalRecords={candidateList?.[0]?.totalRecords}
                                currentPage={selectedFilters?.pageNumber}
                                recordsPerPage={10}
                                handlePageChange={handlePageChange}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Dashboard;
